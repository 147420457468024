/* You can add global styles to this file, and also import other style files */
@import "../node_modules/@ag-grid-community/core/dist/styles/ag-grid.css";
@import "../node_modules/@ag-grid-community/core/dist/styles/ag-theme-alpine/sass/ag-theme-alpine-mixin";
@import "../node_modules/@ag-grid-community/core/dist/styles/ag-theme-alpine-dark/sass/ag-theme-alpine-dark-mixin";

@import "~@angular/material/_theming.scss";
@import "theme";

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.light-theme {
  @include angular-material-theme($light-theme);
  ag-grid-angular {
    @include ag-theme-alpine($ag-theme-alpine-default-params);
  }

  .accsaber-footer {
    display: flex;
    background-color: #fafafa;

    a {
      color: black !important;
    }
  }

  a:link {
    color: #0000ff;
    text-decoration: none;
  }

  a:visited {
    color: #0000ff;
    text-decoration: none;
  }

  .positive-rank {
    color: #178800;
  }

  .negative-rank {
    color: #cc0000;
  }

  .current-page {
    background: lightblue;
    font-weight: bold;
  }

  .contrast-rich-text {
    text-shadow: 0 0 1px white, 0 0 3px white, 0 0 5px white;
    color: black;
  }

  .info {
    color: black;
    background: #66ea66;
  }

  .warn {
    color: black;
    background: #fffb00;
  }

  .error {
    color: white;
    background: #c71515;
  }

  .acc-card {
    @include mat-elevation(3);

    border: #babfc7 solid 1px;

    padding: 10px;
    //border-radius: 5px;
  }
}

.dark-theme {
  @include angular-material-theme($dark-theme);
  ag-grid-angular {
    @include ag-theme-alpine-dark();
  }

  color: white;

  .accsaber-footer {
    display: flex;
    background-color: #303030;

    a {
      color: white !important;
    }
  }

  a:link {
    color: #73a8ff;
    text-decoration: none;
  }

  a:visited {
    color: #73a8ff;
    text-decoration: none;
  }

  .positive-rank {
    color: #00cb00;
  }

  .negative-rank {
    color: #ff1f1f;
  }

  .mat-ink-bar {
    background-color: #839cff !important;
  }

  .current-page {
    background: #373737;
    font-weight: bold;
  }

  .contrast-rich-text {
    text-shadow: 0 0 1px black, 0 0 3px black, 0 0 5px black;
    color: white;
  }

  .info {
    color: black;
    background: #66ea66;
  }

  .warn {
    color: black;
    background: #fffb00;
  }

  .error {
    color: white;
    background: #c71515;
  }

  .acc-card {
    @include mat-elevation(3);

    background: rgb(24, 29, 31);
    border: gray solid 1px;

    padding: 10px;
  }
}

.spacer {
  flex: 1;
}

.accsaber-page {
  position: relative;

  margin: 20px;
  height: calc(100% - 40px);
  width: calc(100% - 40px);
  border-radius: 7px;

  ag-grid-angular {
    @include mat-elevation(5);
    height: 100%;
    width: 100%;
  }
}

.grid-center {
  justify-content: center;
}

.grid-center-cell {
  div {
    span {
      width: 100%;
      text-align: center;
    }
  }
}
// FIXME these styles don't work anymore
.info {
  background: #66ea66;
}

.warn {
  background: #fffb00;
}

.error {
  background: #c71515;
}

.ag-cell-value {
  cursor: text;
}
